import { LatLng, Map, Marker as MarkerEntity, TileLayer as TileLayerEntity } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/lib/components/Button.component';
import { DatePickerRef, Datepicker } from 'src/lib/components/DatePicker.component';
import { Select } from 'src/lib/components/Select.component';
import { TextInput } from 'src/lib/components/TextInput.component';
import { toasting } from 'src/lib/components/Toast.component';
import { fget, fpost } from 'src/lib/fetch';
import { SelectBoxBaseLayer } from 'src/modules/map-layers/components/SelectBoxBaseLayer.component';
import { MarkerMapControl } from 'src/modules/map-markers/components/MarkerMapControl.component';
import { AreaEntity } from '../../dto/area/area.entity';
import { CreateDeviceReq } from '../../dto/device/device.req';
import { ResponseListDocuments } from '../../dto/response';
import { RiverEntity } from '../../dto/river/river.entity';

export function CreateDevicePage() {
  const eForm = useRef<HTMLFormElement>(null);

  const eTextName = useRef<HTMLInputElement>(null);
  const eTextNumber = useRef<HTMLInputElement>(null);
  const eTextType = useRef<HTMLSelectElement>(null);
  const eTextCoordinateX = useRef<HTMLInputElement>(null);
  const eTextCoordinateY = useRef<HTMLInputElement>(null);
  const eSelectRiver = useRef<HTMLSelectElement>(null);
  const eSelectArea = useRef<HTMLSelectElement>(null);
  const eDateCreate = useRef<DatePickerRef>(null);
  const eDateUpdate = useRef<DatePickerRef>(null);
  const eTextWaterLevelFormula = useRef<HTMLInputElement>(null);
  const eTextFlowRateFormula = useRef<HTMLInputElement>(null);
  const eTextVelocityFormula = useRef<HTMLInputElement>(null);
  const eTextCrossSectionFormula = useRef<HTMLInputElement>(null);
  const eTextWaterLevelThreshold = useRef<HTMLInputElement>(null);
  const eTextAlertReceiptEmail = useRef<HTMLInputElement>(null);

  const baseTileLayer = useRef<TileLayerEntity>(new TileLayerEntity(''));
  const map = useRef<Map>(null);
  const eMarker = useRef<MarkerEntity>(null);

  const eButtonSubmit = useRef<HTMLButtonElement>(null);

  const [data, setData] = useState<{
    rivers?: ResponseListDocuments<RiverEntity>;
    areas?: ResponseListDocuments<AreaEntity>;
  }>();

  const navigate = useNavigate();
  const handleButtonSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!eForm.current?.checkValidity()) {
      return eForm.current?.reportValidity();
    }

    try {
      const installDate = eDateCreate.current?.value ? (eDateCreate.current?.value / 1000).toString() : '';
      const removeDate = eDateUpdate.current?.value ? (eDateUpdate.current?.value / 1000).toString() : '';
      const waterLevelThreshold = eTextWaterLevelThreshold.current?.value.toString() || '';
      await fpost<CreateDeviceReq>('api/devices', {
        body: {
          device: {
            DEVICE_NAME: eTextName.current?.value || '',
            DEVICE_NUMBER: eTextNumber.current?.value || '',
            DEVICE_TYPE: eTextType.current?.value || '',
            DEVICE_COORDINATE: `${eTextCoordinateX.current?.value || ''},${eTextCoordinateY.current?.value || ''}`,
            RIVER_NAME: eSelectRiver.current?.value || '',
            AREA_NAME: eSelectArea.current?.value || '',
            INSTALL_DATE: installDate,
            REMOVE_DATE: removeDate,

            WATER_LEVEL_FORMULA: eTextWaterLevelFormula.current?.value || '',
            VELOCITY_FORMULA: eTextVelocityFormula.current?.value || '',
            FLOW_RATE_FORMULA: eTextFlowRateFormula.current?.value || '',
            CROSS_SECTION_FORMULA: eTextCrossSectionFormula.current?.value || '',
            WATER_LEVEL_THRESHOLD: waterLevelThreshold,
            ALERT_RECEIPT_EMAIL: eTextAlertReceiptEmail.current?.value || '',
          },
        },
      });

      navigate('..');
    } catch (error) {
      console.error('Error creating device:', error);
      toasting({ children: 'デバイスの追加に失敗しました。', containerProps: { className: 'border-red-600' } });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const areaResponse = await fget<unknown, ResponseListDocuments<AreaEntity>>('api/areas');

        const areaName = areaResponse?.[1].Items?.[0].AREA_NAME.S;

        const river = await fget(`api/riverByArea?AREA_NAME=${areaName}`);

        setData({ areas: areaResponse?.[1], rivers: river[1] });
      } catch (error) {
        console.error('Error fetching data:', error);
        toasting({ children: 'デバイスの取得に失敗しました。', containerProps: { className: 'border-red-600' } });
      }
    };

    fetchData();
  }, []);

  return (
    <form className="flex flex-row flex-wrap px-4 pt-12 md:px-12 md:py-8" ref={eForm}>
      <h1 className="mb-2 whitespace-nowrap text-2xl">デバイス追加</h1>
      <span className="w-full border-b border-slate-800"></span>

      <div className="mt-4 flex flex-nowrap gap-8">
        <div className="flex w-2/3 flex-row flex-wrap justify-between gap-8">
          <TextInput
            labelProps={{
              children: (
                <>
                  <div>デバイス名</div>
                  <div className="text-[11px]">AWS の IoT Core のモノ (Thing) の名前と一致することが必要</div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'mt-4 w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="device11"
            required
            ref={eTextName}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>デバイス番号</div>
                  <div className="text-[11px]">AWS に送信する GID と一致することが必要</div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="1001"
            required
            ref={eTextNumber}
          />

          <Select
            labelProps={{ value: '種類' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '24rem',
              },
            }}
            required
            ref={eTextType}>
            <option value={'水位と流速セット'}>水位と流速セット</option>
            <option value={'水位のみ'}> 水位のみ</option>
          </Select>

          <div className="flex basis-full flex-col">
            <label className="mb-2 text-sm font-normal text-gray-900 dark:text-white">設置座標</label>
            <div className="flex flex-row flex-wrap justify-between gap-4">
              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'X',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                onChange={(e) => {
                  if (eTextCoordinateY.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateY.current.value));
                }}
                ref={eTextCoordinateX}
              />

              <TextInput
                labelProps={{
                  className: 'mb-0 mr-2',
                  children: 'Y',
                }}
                type="text"
                containerProps={{
                  className: 'flex flex-row items-center',
                  style: {
                    minWidth: '12rem',
                  },
                }}
                required
                onChange={(e) => {
                  if (eTextCoordinateX.current?.value)
                    eMarker.current?.setLatLng(new LatLng(+e.target.value, +eTextCoordinateX.current.value));
                }}
                ref={eTextCoordinateY}
              />
            </div>
          </div>

          <Select
            defaultValue={data?.areas?.Items?.[0]?.AREA_NAME.S}
            labelProps={{ value: '地域名' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            required
            onChange={(e) => {
              fget(`api/riverByArea?AREA_NAME=${e.target.value}`, {}).then((river) => {
                setData((prev) => ({ ...prev, rivers: river[1] }));
              });
            }}
            ref={eSelectArea}>
            {data?.areas?.Items?.map((area, i) => (
              <option key={`Option-` + i} value={area.AREA_NAME.S}>
                {area.AREA_NAME.S}
              </option>
            ))}
          </Select>

          <Select
            defaultValue={data?.rivers?.Items?.[0]?.RIVER_NAME.S}
            labelProps={{ value: '川の名前' }}
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            required
            ref={eSelectRiver}>
            {data?.rivers?.Items?.map((river, i) => (
              <option key={`Option-` + i} value={river.RIVER_NAME.S}>
                {river.RIVER_NAME.S}
              </option>
            ))}
          </Select>

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>水位の計算式</div>
                  <div className="text-[11px]">水面までの距離を X とし計算式を入力ください</div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="99 * X - 100"
            ref={eTextWaterLevelFormula}
          />

          <TextInput
              labelProps={{
                  children: (
                      <>
                          <div>断面積の計算式</div>
                          <div className="text-[11px]">水位をXとして計算式を入力してください</div>
                      </>
                  ),
              }}
              type="text"
              containerProps={{
                  className: 'w-1/3 basis-full',
                  style: {
                      minWidth: '12rem',
                  },
              }}
              placeholder="99 * X - 100"
              ref={eTextCrossSectionFormula}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>流速</div>
                  <div className="text-[11px]">
                    コンデンサ電圧を X とし、計算式を入力ください。CNT の値に基づいて条件分岐 (IF 文)
                    を使用することができます。例：IF(CNT=2 OR CNT=3, 6200-X, X)
                  </div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="3X + 1"
            ref={eTextVelocityFormula}
          />

          <TextInput
            labelProps={{
              children: (
                <>
                  <div>流量</div>
                  <div className="text-[11px]">
                    水位をX, 流速をY, 断面積をZとして計算式を入
                    力してください、計算式を入力ください。CNT の値に基づいて条件分岐
                    (IF 文) を使用することができます。例：IF(CNT=2 OR CNT=3, 6200-X+Y, X+Y)
                  </div>
                </>
              ),
            }}
            type="text"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            placeholder="3X + 1"
            ref={eTextFlowRateFormula}
          />

          <TextInput
              labelProps={{
                children: (
                    <>
                        <div>水位閾値</div>
                        <div className="text-[11px]">
                            水位が閾値を超えた場合、メールで通知します。
                        </div>
                    </>
                ),
              }}
              type="number"
              containerProps={{
                  className: 'w-1/3 basis-full',
                  style: {
                      minWidth: '12rem',
                },
              }}
              ref={eTextWaterLevelThreshold}
          />

            <TextInput
                labelProps={{
                    children: (
                        <>
                            <div>通知先</div>
                            <div className="text-[11px]">
                                example: hung.pioner@pionero.io, nam.pionero@pionero.io, anh.pionero@pionero.io
                            </div>
                        </>
                    ),
                }}
                type="text"
                containerProps={{
                    className: 'w-1/3 basis-full',
                    style: {
                        minWidth: '12rem',
                    },
                }}
                ref={eTextAlertReceiptEmail}
            />

          <Datepicker
            labelProps={{
              children: '設置日時',
            }}
            messages={{
              moveToday: '今日',
            }}
            className="w-full"
            locale="ja"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            ref={eDateCreate}
          />

          <Datepicker
            labelProps={{
              children: '撤去・入れ替え日時',
            }}
            messages={{
              moveToday: '今日',
            }}
            className="w-full"
            locale="ja"
            containerProps={{
              className: 'w-1/3 basis-full',
              style: {
                minWidth: '12rem',
              },
            }}
            ref={eDateUpdate}
          />

          <Button
            className="mx-auto basis-full justify-center justify-self-center rounded-md bg-black text-xs text-white"
            style={{
              minWidth: '256px',
              maxHeight: '2.5rem',
            }}
            type="submit"
            onClick={handleButtonSubmit}
            ref={eButtonSubmit}>
            追加
          </Button>
          <Link
            className={'mx-auto basis-full rounded-md border border-gray-600  p-2 text-center text-xs'}
            to=".."
            style={{
              minWidth: '256px',
            }}>
            キャンセル
          </Link>
        </div>

        <MapContainer
          attributionControl={false}
          minZoom={5}
          zoom={9}
          center={[33.9195, 133.1811]}
          className="mt-4"
          style={{
            height: '100%',
            width: '20vw',
            minWidth: '48rem',
            border: '0.25rem solid',
            flexBasis: '100%',
          }}
          ref={map}>
          <TileLayer
            url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
            ref={baseTileLayer}
            maxNativeZoom={18}
          />
          <Control
            position="topright"
            container={{
              className: 'flex bg-none flex-col',
            }}>
            <SelectBoxBaseLayer baseTileLayer={baseTileLayer} />
          </Control>

          <MarkerMapControl
            ref={eMarker}
            onClickMap={(e) => {
              eTextCoordinateX.current &&
                eTextCoordinateY.current &&
                (eTextCoordinateX.current.value = `${e.latlng.lat}`) &&
                (eTextCoordinateY.current.value = `${e.latlng.lng}`);

              return true;
            }}
          />
        </MapContainer>
      </div>
    </form>
  );
}
